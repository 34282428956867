import React from 'react';

// External Components
import {
  Section,
  Heading,
  Paragraph,
  FlexWrapper,
  Box,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';

// helper function
import { convertApiHtmlText } from 'boxenStopHooks';

export const Article = ({
  data: { headline, author, date, full_description, headline_image }
}) => {
  return (
    // Markup
    <Section id="blog-post__article">
      <HeadlineWrapper headline={headline} author={author} date={date} />
      {/* slug - 1 is meat to access the correct local static image before image can be fetched from the cms as gatsby image */}
      <Image data={headline_image} />
      <Description data={convertApiHtmlText(full_description.html)} />
    </Section>
  );
};

// Elements

const HeadlineWrapper = ({ headline, author, date }) => (
  <Box
    className="blog-post__article__headline-wrapper"
    sx={{
      gridColumn: [
        '1/13',
        '2/span 10',
        '4/span 18',
        '4/span 18',
        '5/span 16',
        '6/span 14'
      ]
    }}>
    <Headline data={headline} />
    <AuthorAndDate author={author} date={date} />
  </Box>
);

const Headline = ({ data }) => (
  <Heading
    as="h1"
    sx={{
      fontSize: ['2.8rem', '4rem', '4.4rem', '2.8rem', '4.4rem', '4.8rem'],
      lineHeight: 1.1,
      fontFamily: 'primary.bold'
    }}>
    {data}
  </Heading>
);

const AuthorAndDate = ({ author, date }) => (
  <FlexWrapper
    className="headline-wrapper__author-and-date"
    sx={{
      alignItems: 'center',
      gap: '1.6rem',
      mt: ['1.6rem', '1.6rem', '1.6rem', '1.2rem', '2.4rem', '2rem']
    }}>
    <Paragraph
      sx={{
        fontFamily: 'body.medium',
        fontSize: ['1.4rem', '1.7rem', '1.7rem', '1.5rem', '1.7rem', '1.8rem'],
        lineHeight: 1.25
      }}>
      {author}
    </Paragraph>
    <Paragraph
      sx={{
        color: 'grey',
        fontFamily: 'body.medium',
        fontSize: ['1.2rem', '1.4rem', '1.4rem', '1.3rem', '1.4rem', '1.5rem'],
        lineHeight: 1,
        textTransform: 'uppercase'
      }}>
      {date}
    </Paragraph>
  </FlexWrapper>
);

const Image = ({ data: { image, alt } }) => (
  <ImageContainer
    src={image}
    alt={alt}
    sx={{
      aspectRatio: ['1/0.57', '1/0.56', '1/0.56', '1/0.56', '1/0.56', '1/0.56'],
      borderRadius: 'none',
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 22',
        '4/span 18',
        '4/span 18',
        '4/span 18'
      ],
      mt: ['4.4rem', '5.1rem', '5.6rem', '4.8rem', '5.8rem', '6.4rem'],
      mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0]
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      '&>a': { textDecoration: 'underline' },
      fontSize: ['1.7rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '2rem'],
      gridColumn: [
        '1/13',
        '2/span 10',
        '4/span 18',
        '4/span 18',
        '6/span 14',
        '6/span 14'
      ],
      lineHeight: 1.6,
      mt: ['6.4rem', '7.2rem', '7.4rem', '5.6rem', '7.9rem', '10.2rem']
    }}
  />
);
