import React from 'react';

// Sections
import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { Article, MoreArticles } from 'sections/blogPost';

// Local Components
import { Spacer } from 'components';

// Data
import { useBlogPageData } from 'sections/blogPage';

// External Data
import { useKisBlogPostArticlesData, useKisBlogPageData } from 'boxenStopHooks';

// Helper Function
import { convertApiHtmlText } from 'boxenStopHooks/helper';

const BlogPost = (props) => {
  const postData = props.pageContext.data;
  const { readLink } = useBlogPageData();
  const cmsAllArticles = useKisBlogPostArticlesData();

  const { more_articles: cmsMoreArticles } = useKisBlogPageData();

  return (
    <>
      <SEO
        title={postData.headline}
        description={postData.brief_description}
        shortTitle={postData.headline}
        shortDescription={postData.brief_description.slice(0, 197) + '...'}
        url={`https://www.einfachpurgeniessen.de/blog-post/${postData.slug}`}
        author={postData.author}
      />
      <Spacer
        height={['4rem', '5.5rem', '4.8rem', '3.2rem', '7rem', '6.9rem']}
      />
      <Article data={postData} />
      <Spacer
        height={['5.4rem', '12.5rem', '13.8rem', '11.3rem', '15rem', '15rem']}
      />
      <MoreArticles
        headline={convertApiHtmlText(cmsMoreArticles.headline.html)}
        readLink={readLink}
        articles={cmsAllArticles.filter((post) => post.slug !== postData.slug)}
      />
      <Spacer
        height={['15rem', '12.6rem', '15rem', '10rem', '16.5rem', '24.8rem']}
      />
    </>
  );
};

export default BlogPost;
