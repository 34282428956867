import React from 'react';

// External Components
import { Section, Heading, Box } from '@thepuzzlers/pieces';

// Local Components
import { HorizontalLine, BlogCard } from 'components';

export const MoreArticles = ({ headline, articles, readLink }) => {
  return (
    // Markup
    <ContentWrapper>
      <HeadlineWrapper data={headline} />
      <BlogCards data={articles} readLink={readLink} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <Section id="blog-post__more-articles">{children}</Section>
);

const HeadlineWrapper = ({ data }) => (
  <Box
    className="more-articles__headline-wrapper"
    sx={{
      gridColumn: [
        '1/13',
        '2/span 10',
        '4/span 18',
        '2/span 22',
        '3/span 20',
        '4/span 18'
      ]
    }}>
    <Heading
      as="h2"
      sx={{
        fontSize: ['2.8rem', '3.4rem', '3.6rem', '2.8rem', '3.6rem', '4.8rem'],
        lineHeight: 1.1,
        fontFamily: 'primary.bold'
      }}>
      {data}
    </Heading>
    <HorizontalLine
      sx={{ mt: ['1.2rem', '1.6rem', '1.6rem', '1.2rem', '1.6rem', '1.6rem'] }}
    />
  </Box>
);

const BlogCards = ({ data, readLink }) => {
  return data.map(({ headline, brief_description, slug, cover_image }) => (
    <BlogCard
      key={slug}
      headline={headline}
      briefDescription={brief_description}
      // cover Image still static at the moment
      coverImage={cover_image}
      readLink={readLink}
      slug={slug}
      sx={{
        gridColumn: [
          '1/13',
          '2/span 10',
          '4/span 16',
          '2/span 22',
          '3/span 20',
          '4/span 18'
        ],
        ':first-of-type': {
          mt: ['4.8rem', '6.4rem', '5.6rem', '4.8rem', '4.8rem', '7.2rem']
        },
        ':not(:first-of-type)': {
          mt: ['12rem', '12rem', '12rem', '7.2rem', '8.8rem', '9.6rem']
        }
      }}
    />
  ));
};
