import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { BlogCard } from 'components';

export const BlogCards = ({ data, readLink }) => {
  return (
    // Markup
    <Section id="blog-page__blog-cards">
      {data.map((article) => {
        const { headline, brief_description, slug, cover_image } = article;
        return (
          <BlogCard
            key={slug}
            headline={headline}
            briefDescription={brief_description}
            coverImage={cover_image}
            readLink={readLink}
            slug={slug}
            sx={{
              gridColumn: [
                '1/13',
                '2/span 10',
                '5/span 16',
                '2/span 22',
                '2/span 20',
                '3/span 18'
              ]
            }}
          />
        );
      })}
    </Section>
  );
};
