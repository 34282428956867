import React from 'react';

// External Components
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { GridSpacer, DownArrow } from 'components';

// Helper Function
import { convertApiHtmlText } from 'boxenStopHooks/helper';

export const Header = ({ data: { headline, description } }) => {
  return (
    // Markup
    <Section id="blog-page__header">
      <Headline data={convertApiHtmlText(headline.html)} />
      <Spacer />
      <Description data={convertApiHtmlText(description.html)} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <Heading
    as="h1"
    sx={{
      fontSize: ['4rem', '5.2rem', '6rem', '3.6rem', '5.4rem', '5.6rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '3/span 20',
        '2/span 18',
        '2/span 18',
        '3/span 15'
      ],
      lineHeight: 1.1,
      fontFamily: 'primary.bold',
      '& > span': {
        fontFamily: 'primary.italic'
      }
    }}
    dangerouslySetInnerHTML={{ __html: data }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    sx={{
      fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.6rem', '1.8rem', '2rem'],
      gridColumn: [
        '1/13',
        '2/span 10',
        '5/span 16',
        '10/span 14',
        '13/span 11',
        '13/span 10'
      ],
      lineHeight: 1.5
    }}>
    {data}
  </Paragraph>
);

const Spacer = () => (
  <GridSpacer
    height={['3.2rem', '4rem', '5.6rem', '6.9rem', '8rem', '9.3rem']}
  />
);
