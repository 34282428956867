import { useStaticQuery, graphql } from 'gatsby';

export const useBlogPageData = () => {
  const data = useStaticQuery(graphql`
    query {
      blogDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        # this readlink is prepared for used by the blog card that fetched from the API, latter we will remove all static data of articles below
        readLink {
          label
        }
      }
    }
  `);

  return data.blogDataJson;
};
